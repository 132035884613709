.photoWidgetImageHolder {
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: end;
  align-items: end;
  aspect-ratio: 16/9;
  width: auto;
  padding: 10px;
  border-radius: 4px;
}

.placeHolderClickableStyle {
  cursor: pointer;
}

.disabledStyle {
  pointer-events: none;
}
