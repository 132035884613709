.whatsNextCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #e7e6e4;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
}

.sidebarLink {
  position: relative;
}

.sidebarLinkActive {
  background: #f6f5fd;
  color: #493ab1;
}

.sidebarLink:hover {
  cursor: pointer;
}

.sidebarLinkActive:before {
  content: "";
  position: absolute;
  left: 0;
  width: 3px;
  height: 60%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #493ab1;
}

.whatsNextCardHeader {
  display: flex;
  padding-bottom: 10px;
  border-bottom: solid 1.5px #2e384d2e;
}

.whatsNextCardHeader .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsNextCardHeader .loaderHolder {
  margin-right: 10px;
}

.loader {
  position: relative;
}

.loader .text {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
}

.importantSteps {
  display: flex;
}

.importantSteps img {
  margin-right: 8px;
}

.importantSteps .step {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.importantSteps .step .stepNumber {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #493ab125;
  color: #493ab1;
  margin-right: 10px;
  font-size: 14px;
}

.importantSteps .step .stepText {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.importantSteps .stepTextActive {
  color: #493ab1 !important;
  cursor: pointer;
}

.importantSteps .stepArrow {
  margin: 16px 0 0 16px;
  display: flex;
  align-items: center;
}

.whatsNextCardTitle {
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 780px) {
  .whatsNextCardTitle {
    font-size: 20px;
    font-weight: 500;
  }
  .linkWhatsNextCard {
    max-width: 200px;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .importantSteps .stepArrow {
    display: none;
  }
  .importantSteps {
    display: flex;
    flex-direction: column;
  }
  .whatsNextCardHeader {
    font-size: 14px !important;
  }
  .linkWhatsNextCard {
    max-width: 200px;
    overflow: hidden;
  }
}
