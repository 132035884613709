.formDesc {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: rgba(39, 37, 34, 0.6);
}

.modalPaperClassName {
  max-width: 428px !important;
  min-width: 428px;
  overflow: visible !important;
}

/* custom style for exly modal mobile footer */
.mobileModalFooter {
  left: -23px !important;
}
