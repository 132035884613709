.modal {
  position: absolute;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 5px 0px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.cropperStyle {
  display: flex;
  width: 50%;
  max-width: 100%;
  height: fit-content;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.cropperBackground {
  width: 97%;
  height: fit-content;
  margin: 10px;
  display: flex;
  max-height: fit-content;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: gray;
}
.overlay {
  opacity: 1;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.drawerWidth {
  width: 30vw;
  padding: 1vw;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.titleText {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 600;
  margin-top: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: center;
  color: #212121;
}

.headerText {
  border: none;
  padding: 12px 8px;
  width: 100%;
  align-content: center;
  justify-content: center;
  line-height: 1.48;
  text-align: start;
  letter-spacing: normal;
  margin: 8px 0px;
  border-radius: 24px;
  background-color: #f2f2f4;
}

.cardImage {
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.searchInput {
  border: none;
  background: none;
  width: 100%;
  color: #666666;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.button {
  padding: 2px 14px;
  border-radius: 4px;
  border: 2px solid #6c5dd3;
  background-color: white;
  color: #6c5dd3;
  margin: 16px 0;
}

.imageText {
  color: #ffffff !important;
  margin: 0.5vw;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
}

.credit {
  opacity: 0.7;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin: 8px 0;
  align-self: start;
  line-height: 1.48;
  letter-spacing: normal;
  color: #212121;
}

.cropper {
  margin: 5px;
  align-self: center;
  width: 100%;
  max-height: fit-content;
  justify-content: center;
  align-items: center;
}

.upload_device_background {
  border: 4px dashed #b8b8ff;
  background: white;
  width: 100%;
  flex: 0 0 auto;
  padding: 16px;
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
}

.cropText {
  background-color: #6c5dd3;
  padding: 7px 20px;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 00px 0px 10px;
}

.cancelText {
  background-color: #999999;
  padding: 7px 20px;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.label {
  width: 100px;
  height: 100px;
}

.openCloseModal {
  width: 97%;
  display: flex;
  margin: 0 5px;
  padding-bottom: 5px;
  flex-direction: row;
  align-self: center;
  align-content: center;
  justify-content: space-between;
}

.container {
  position: relative;
  width: 50%;
}

.image {
  opacity: 1;
  display: block;
  transition: 1s ease;
  backface-visibility: hidden;
}

.middle {
  position: absolute;
  transition: 0.5s ease;
  opacity: 0;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: black;
}

.container:hover .image {
  opacity: 0.5;
}

.container:hover .middle {
  opacity: 0.7;
}

.text {
  background-color: #666666;
  font-size: 16px;
  padding: 16px 32px;
}

.horizontalContainer {
  display: flex;
  flex-direction: row;
}
.line {
  border: 1px solid black;
  width: 100%;
}
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 16px;
  height: 16px;
  margin: 4px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cropContainer {
  /* position: relative !important; */
  position: relative !important;
  width: 100% !important;
  height: 380px !important;
}

.cropmodalcontainer {
  padding: 0;
  position: relative;
}
.cropcontrol {
  position: relative;
  background-color: rgba(0, 0, 0, 2.4);
  padding-bottom: 20px;
  /* padding-bottom: 5px 20px 30px; */
}
.zoomcrop {
  margin-bottom: 20px;
  display: flex;
  padding: 30px 20px 10px;
  border-bottom: 1px solid white;
}
.zoomText {
  color: white;
  padding-right: 20px;
}
.zoomcrop span {
  color: white !important;
}
.zoomcrop > span {
  width: 35% !important;
}
.headingcropper {
  padding: 20px;
  background-color: rgba(0, 0, 0, 2.4);
  font-size: 18px;
  font-weight: 600;
  color: white;
}
.dragContainer {
  padding: 10px;
  background-color: rgba(0, 0, 0, 2.4);
  color: white;
  bottom: 2%;
  left: 33%;
  position: absolute;
  display: inline-block;
}
.dragContainer > img,
.dragContainer > div {
  display: inline-block;
  padding: 0 3px;
}
.cropperbutton {
  background-color: white;
  color: black;
}
.croppercontainerbtn {
  padding: 0 20px;
  padding-top: 5px;
}
@media screen and (max-width: 780px) {
  .drawerWidth {
    width: 80vw;
    padding: 1vw;
  }
  .openCloseModal {
    width: 100%;
    align-self: center;
    align-content: center;
    justify-content: center;
  }

  .modal {
    max-width: 350px;
    top: 40%;
    left: 0;
    transform: none;
    margin: 0px 15px;
  }

  .dragContainer {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    bottom: 2%;
    left: 15%;
    position: absolute;
    display: inline-block;
  }
}

.imagePreview {
  object-fit: cover;
}
